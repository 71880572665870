<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-radio-group
          v-model="seatsType"
          row
          @change="handleChange"
          hide-details
          style="margin: 0;"
        >
          <v-radio
            :label="$t('booking.activities.seatsType.byCapacity', locale)"
            value="byCapacity"
          />
          <v-radio
            :label="$t('booking.activities.seatsType.byNumReservation', locale)"
            value="byNumReservation"
          />
          <v-radio
            :label="$t('booking.activities.seatsType.byResources', locale)"
            value="byResources"
          />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="seatsType === 'byResources'"
        cols="12"
        sm="12"
        md="12"
      >
        <v-autocomplete
          v-model="resourceID"
          :items="resources"
          outlined
          dense
          hideDetails
          itemText="Name"
          itemValue="ID"
          label="Recursos"
          @change="handleChange"
        />
      </v-col>
      <v-col
        v-if="seatsType === 'byCapacity'"
        cols="12"
        sm="6"
        md="6"
      >
        <custom-text-field-number
          v-model="seats"
          :label="$t('booking.activities.seatsActivity', locale)"
          @input="handleChange"
        />
      </v-col>
      <v-col
        v-else-if="seatsType === 'byNumReservation'"
        cols="12"
        sm="6"
        md="6"
      >
        <custom-text-field-number
          v-model="bookingLimit"
          :label="$t('booking.activities.bookingLimit', locale)"
          @input="handleChange"
        />
      </v-col>
      <v-col
        v-if="seatsType !== 'byResources'"
        cols="12"
        sm="6"
        md="6"
      >
        <custom-text-field-number
          v-model="seatsByBooking"
          :label="$t('booking.activities.seatsByBooking', locale)"
          @input="handleChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
export default {
  components: {
    CustomTextFieldNumber,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    seatsType: 'byCapacity',
    resources: [],
    seatsByBooking: null,
    seats: null,
    bookingLimit: null,
    resourceID: null,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isByResources () {
      return this.seatsType === 'byResources' ? true : false
    },
    isByNumReservation () {
      return this.seatsType === 'byNumReservation' ? true : false
    },
    isByCapacity () {
      return this.seatsType === 'byCapacity' ? true : false
    },
  },
  watch: {
    editedItem () {
      this.prepareData()
    },
  },
  mounted () {
    this.handleGetResources()
    this.prepareData()
  },
  methods: {
    handleGetResources () {
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/resources`)
        .then(response => {
          if(!response) return
          this.resources = response.map(item => {
            item.Name = utils.getTranslation(item.Name, this.locale)
            return item
          })
        })
    },
    prepareData () {
      if (!this.editedItem) return
      this.seatsType = this.editedItem.SeatsType ? this.editedItem.SeatsType : 'byCapacity'
      this.bookingLimit = this.editedItem.BookingLimit
      this.seats = this.editedItem.Seats
      this.seatsByBooking = this.editedItem.SeatsByBooking
      this.resourceID = this.editedItem.ResourceID
    },
    handleChange () {
      let SeatsType = this.seatsType
      let SeatsByBooking = 0
      let BookingLimit = 0
      let Seats = 0
      let ResourceID = null
      if (this.isByResources) {

        const aux = JSON.parse(JSON.stringify(this.resources.filter(x => x.ID === this.resourceID))).shift()
        ResourceID = this.resourceID
        SeatsByBooking = aux && aux.ResourceItemsCount ? aux.ResourceItemsCount : 0
        BookingLimit = aux && aux.ResourceItemsCount ? aux.ResourceItemsCount : 0
        Seats = aux && aux.ResourceItemsCount ? aux.ResourceItemsCount : 0

      } else if (this.isByNumReservation) {

        SeatsByBooking = this.seatsByBooking
        BookingLimit = this.bookingLimit
        Seats = this.seatsByBooking * this.bookingLimit

      } else if (this.isByCapacity) {

        SeatsByBooking = this.seatsByBooking
        BookingLimit = this.seats
        Seats = this.seats

      }
      const result = {
        SeatsType,
        SeatsByBooking,
        BookingLimit,
        Seats,
        ResourceID,
      }
      /*
      alert('aaa')
      const result = {
        SeatsType: this.seatsType,
        SeatsByBooking: this.seatsByBooking,
        BookingLimit: this.seatsType === 'byNumReservation' ? this.bookingLimit : this.seats,
        Seats: this.seatsType === 'byCapacity' ? this.seats : this.seatsByBooking * this.bookingLimit,
        ResourceID: this.resourceID
      }*/
      this.onChange(result)
    },
  },
}
</script>

